<template>
  <div>
    <sqr-input-time
    />
  </div>
</template>

<script>
import SqrInputTime from '@/sqrd-ui/SqrInputTime';
export default {
  name: 'TimeEdit',
  components: { SqrInputTime }
};
</script>
